"use strict";

// Hamburguer menu
$(function () {
  $(".hamburger").click(function () {
    $(this).toggleClass("is-active");
    var $menu = $(".lateral-menu");
    var $burger = $(".hamburger");

    if ($($menu).css("right") === "-280px") {
      $($menu).css({
        display: 'block'
      });
      $($burger).css({
        position: 'fixed'
      });
      $($menu).animate({
        right: '0'
      }, {
        queue: false,
        duration: 200
      });
    } else {
      $($menu).animate({
        right: '-280px'
      }, {
        queue: false,
        duration: 200
      });
      $($menu).css({
        display: 'none'
      });
      $($burger).css({
        position: 'absolute'
      });
    }
  });
});

function owlInitializeDiferenciais() {
  if ($(window).width() < 600) {
    $('.diferenciais-carousel').addClass("owl-carousel");
    $('.owl-carousel').owlCarousel({
      loop: true,
      nav: true,
      navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
      owl2row: true,
      responsive: {
        0: {
          items: 1
        }
      }
    });
  } else {
    $('.owl-carousel').owlCarousel('destroy');
    $('.diferenciais-carousel').removeClass("owl-carousel");
  }
}

function owlInitializeBitsuporte() {
  if ($(window).width() < 600) {
    $('.bitsuporte-carousel').addClass("owl-carousel");
    $('.owl-carousel').owlCarousel({
      loop: true,
      nav: true,
      center: true,
      margin: 50,
      navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
      autoWidth: true,
      responsive: {
        0: {
          items: 1
        }
      }
    });
  } else {
    $('.owl-carousel').owlCarousel('destroy');
    $('.bitsuporte-carousel').removeClass("owl-carousel");
  }
}

function owlInitializeParceiros() {
  $('.parceiros-carousel').addClass("owl-carousel");
  $('.owl-carousel').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 4
      }
    }
  });
}

function owlInitializeCases() {
  console.log('cases');

  if ($(window).width() <= 576) {
    $('.cases-carousel').addClass("owl-carousel");
    $('.owl-carousel').owlCarousel({
      nav: true,
      navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
      responsive: {
        0: {
          items: 1
        }
      }
    });
  } else if ($(window).width() <= 960) {
    $('.cases-carousel').addClass("owl-carousel");
    $('.owl-carousel').owlCarousel({
      owl2row: true,
      responsive: {
        0: {
          items: 1
        }
      }
    });
  } else {
    $('.owl-carousel').owlCarousel('destroy');
    $('.cases-carousel').removeClass("owl-carousel");
  }
}

function getCurrentFileName() {
  var pagePathName = window.location.pathname;
  return pagePathName.substring(pagePathName.lastIndexOf("/") + 1);
}

function hideRedLogo() {
  if ($(window).width() < 1140) {
    var selectorHeader = $("header");
    selectorHeader.children("a").children("img").first().removeClass("d-md-none");
    selectorHeader.children("a").children("img").last().removeClass("d-md-flex");
  } else {
    var selectorDesktopLogo = $(".desktop-logo");
    selectorDesktopLogo.children("img").first().removeClass("d-none");
    selectorDesktopLogo.children("img").last().removeClass("d-lg-flex");
  }
}

function setupCurrentPage() {
  var currentDocument = getCurrentFileName();

  if (currentDocument === '' || currentDocument === 'index.html') {
    owlInitializeBitsuporte();
    owlInitializeDiferenciais();
    owlInitializeParceiros();
  }

  if (currentDocument === 'cases.html') {
    owlInitializeCases();
  }

  if (currentDocument === 'servicos.html' || currentDocument === 'areadocliente.html' || currentDocument === 'cases.html') {
    hideRedLogo();
  }
}

$(document).ready(setupCurrentPage());
$(window).resize(setupCurrentPage());
/* Attach a submit handler to the form */

$("#formContact").submit(function (event) {
  $("#formContact").addClass("no-show");
  $("#form-wait").removeClass("no-show");
  var ajaxRequest;
  /* Stop form from submitting normally */

  event.preventDefault();
  /* Clear result div*/
  // $("#form-result").html('');

  /* Get from elements values */

  var values = $(this).serialize();
  /* Send the data using post and put the results in a div. */

  /* I am not aborting the previous request, because it's an
     asynchronous request, meaning once it's sent it's out
     there. But in case you want to abort it you can do it
     by abort(). jQuery Ajax methods return an XMLHttpRequest
     object, so you can just use abort(). */

  ajaxRequest = $.ajax({
    url: "php/send.php",
    type: "post",
    data: values
  });
  /*  Request can be aborted by ajaxRequest.abort() */

  ajaxRequest.done(function (response, textStatus, jqXHR) {
    $("#form-wait").addClass("no-show"); // Show successfully for submit message

    $("#form-result").removeClass("no-show");
  });
  /* On failure of request this function will be called  */

  ajaxRequest.fail(function () {
    $("#form-wait").addClass("no-show"); // Show error

    $("#form-error").removeClass("no-show");
  });
});
$("#error-button").click(function () {
  $("#form-error").addClass("no-show");
  $("#formContact").removeClass("no-show");
});
$("#success-button").click(function () {
  $("#form-result").addClass("no-show");
  $("#formContact").removeClass("no-show");
});